import { css } from "@emotion/css";
import { FC } from "react";
import { mediaQuery } from "../../../utils/ui";

interface Props {
  title: string;
  onclick: Function;
  actionStatus: string;
}

export const SeeDetailsButton: FC<Props> = ({
  title = "",
  onclick,
  actionStatus,
}) => {
  return (
    <div
      className={actionStatus === "enable" ? doneStyle : seeDetailStyle}
      onClick={() => onclick()}
    >
      <span
        className={
          actionStatus === "enable" ? doneTitleStyle : seeDetailTitleStyle
        }
      >
        {title}
      </span>
    </div>
  );
};

const titleStyle = css`
  width: 63px;
  height: 28px;
  font-size: 21px;
  font-weight: 600;
  text-align: center;
  ${mediaQuery.landscape} {
    width: 63px;
    height: 28px;
    font-size: 21px;
    font-weight: 600;
    text-align: center;
  }
`;

const doneTitleStyle = css`
  ${titleStyle};
  color: #fff;
`;

const seeDetailTitleStyle = css`
  ${titleStyle};
  color: #01b64e;
`;

export const buttonWidth = 311;
export const divStyle = css`
  width: 311px;
  height: 54px;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 14px;
  border-radius: 8px;
  ${mediaQuery.portrait} {
    width: 311px;
    height: 54px;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 14px;
    border-radius: 8px;
  }
  ${mediaQuery.landscape} {
    width: ${buttonWidth}px;
    height: 54px;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 14px;
    border-radius: 8px;
  }
`;

const doneStyle = css`
cursor: pointer;
  ${divStyle};
  background-color: #16cb63;
  box-shadow: 0 13px 16px 0 rgba(0, 206, 88, 0.2);
`;

const seeDetailStyle = css`
cursor: pointer;
  ${divStyle};
  border: solid 2px #16cb63;
`;
