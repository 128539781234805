import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";

export const client = new GraphQLClient(
  process.env["NEXT_PUBLIC_HASURA_BASE_URL"] as string
);

// export const GRAPHQL_TAG_TYPE = {
//   USER_ACTIONS: "UserActions",
//   VIEW_NOW: "ViewNow",
//   USERS: "Users",
// } as const;

export const api = createApi({
  baseQuery: graphqlRequestBaseQuery({ client }),
  reducerPath: "graphql",
  endpoints: () => ({}),
  // tagTypes: [GRAPHQL_TAG_TYPE.USER_ACTIONS, GRAPHQL_TAG_TYPE.VIEW_NOW, GRAPHQL_TAG_TYPE.USERS],
});
