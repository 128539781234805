import { IndexedAccessType } from "@babel/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { WritableDraft } from "immer/dist/internal";
// import { badgeGetArray, BadgeGetInfo } from "../../utils/bingo/badge";


// エリアに必要なprops
export type compareAreaData = {
        prefecture: string,
        municipalitie: string,
        designatedcity: string,
        oaza: string,
        azaChome: string,
        prefecturesOptions: {value: string, label: string}[],
        municipalitiesOptions: {value: string, label: string}[],
        designatedCitiesOptions: {value: string, label: string}[],
        oazaOptions: {value: string, label: string}[],
        azaOptions: {value: string, label: string}[],
}

// その配列
export type compareAreaState = {
    areaArray: compareAreaData[],
    areaNames: string[]
};

const initialState: compareAreaState = {
    areaArray: [
        {
        prefecture: '',
        municipalitie: '',
        designatedcity: '',
        oaza: '',
        azaChome: '',
        prefecturesOptions: [],
        municipalitiesOptions: [],
        designatedCitiesOptions: [],
        oazaOptions: [],
        azaOptions: [],
    }],
    areaNames: []
};

function addItem(array: compareAreaData[], item: compareAreaData) {
  return Array.from(new Set([...array, item]));//重複データが入らないようにするための対応
}

// エリア情報リストから1つ削除
function removeItem(array: compareAreaData[], index: number) {
    return array.splice(index, 1);
};

// エリア情報の値を更新する
export const updateItem = (array: compareAreaData[], payload: { index: number; value: compareAreaData; }) => {
    return [
        ...array.slice(0, payload.index),
        payload.value,
        ...array.slice(payload.index + 1)
    ]

}

const compareSlice = createSlice({
  name: "compare",
  initialState,
  reducers: {
    setAreaNames: (state, action: PayloadAction<string[]>) => {
        return {
            ...state,
            areaNames: action.payload
        }
    },
    setAddArea: (state, action: PayloadAction<compareAreaData>) => {
        return {
            ...state,
            areaArray: addItem(state.areaArray, action.payload)
        }
    },
    removeArea: (state, action: PayloadAction<number>) => {
        return {
            ...state,
            areaArray: removeItem(state.areaArray, action.payload)
        }
    },
    updateAreaData: (state, action:PayloadAction<{index: number, value: compareAreaData}>) => {
        return {
            ...state,
            areaArray: updateItem(state.areaArray, action.payload)
        }
    },
  },
});

export default compareSlice.reducer;

export const {
    setAreaNames,
    setAddArea,
    removeArea,
    updateAreaData
} = compareSlice.actions;
