import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectedRowData } from "../../components/molucules/FacilityDataGrid";

// const initialState: SelectedRowData[] = [];
interface DataState {
  selectedRows: SelectedRowData[];
}

const initialState: DataState = {
  selectedRows: [],
};

const facilitiesSlice = createSlice({
  name: "facilities",
  initialState,
  reducers: {
    saveData: (state, action: PayloadAction<SelectedRowData[]>) => {
      state.selectedRows = action.payload;
    },
  },
});

export default facilitiesSlice.reducer;

export const {
    saveData,
} = facilitiesSlice.actions;
