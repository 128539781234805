export const path = {
  root: "/",
  //accountRegist: "/account/regist",
  //accountDelete: "/account/delete",
  //contactUs: "/contactUs",
  logout: "/logout",
  terms: "/contents/terms.html",
  faq: "/contents/faq.html",
  privacyPolicy: "/contents/privacyPolicy.html",
  error: "/error",
};
