import "../../styles/globals.css";
import { css } from "@emotion/css";
import type { AppProps } from "next/app";
import { Provider } from "react-redux";
import store from "../services/store";
import { Auth0Provider } from "@auth0/auth0-react";

import "modern-css-reset/dist/reset.min.css";
import { path } from "../utils/ui/route";
import { checkDisplay, Display, DisplayContext, mediaQuery } from "../utils/ui";
import { FC, Profiler, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import ErrorBoundary from "../utils/error/errorboundary";
import Head from "next/head";
// import {ErrorBoundary} from 'react-error-boundary'
// import { ErrorFallback } from "../utils/error/dummy";
import { LicenseInfo } from "@mui/x-data-grid-pro";

const auth0Domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN || "";
const auth0ClientId = process.env.NEXT_PUBLIC_CLIENT_ID || "";
const apiAudience = process.env.NEXT_PUBLIC_AUTH0_API_AUDIENCE || "";
const gtmId = process.env.NEXT_PUBLIC_GTM_ID || "";
const sessionCheckExpiryDays = new Number(
  process.env.NEXT_PUBLIC_AUTH0_SESSION_CHECK_EXPIRY_DAYS || "30"
).valueOf();

const AUTH0_API_SCOPE = "openid name email nickname";
let isGtmInit = false;

function GreenApp({ Component, pageProps }: AppProps) {
  // data-grid license
  LicenseInfo.setLicenseKey(
    "dbf2fd292523edd23103699e24402df5Tz02MTMzMixFPTE3MDk2ODc0Mjc3NjgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );
  const redirectUri =
    typeof window !== "undefined"
      ? `${window.location.origin}${path.root}`
      : "";

  const [display, setDisplay] = useState<Display>("TAB_PORTRAIT");
  useEffect(() => {
    const updateDisplay = () => {
      const currentDisplay = checkDisplay();
      if (currentDisplay !== display) setDisplay(currentDisplay);
    };
    updateDisplay();
    window.addEventListener("resize", () => updateDisplay());
  }, [display]);

  useEffect(() => {
    // Google Tag Manager初期設定
    if (!isGtmInit) {
      const tagManagerArgs = {
        gtmId: gtmId,
      };
      TagManager.initialize(tagManagerArgs);
      isGtmInit = !isGtmInit;
    }
  }, []);

  useEffect(() => {
    //reset css のmin-height設定を上書き
    document.body.style.minHeight = "100%";
    document.body.style.overflow = "auto";
  }, []);

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      audience={apiAudience}
      npm
      scope={AUTH0_API_SCOPE}
      redirectUri={redirectUri}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      sessionCheckExpiryDays={sessionCheckExpiryDays}
    >
      <Provider store={store}>
        <DisplayContext.Provider value={display}>
          <Head>
            <meta
              name="viewport"
              content="width=device-width,initial-scale=1.0,maximum-scale=1.0"
            />
            <meta name="robots" content="noindex" />
            <title>ZeroCa</title>
            <link
              rel="apple-touch-icon"
              href={`./apple-touch-icon.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`}
              sizes="180x180"
            />
            <link
              rel="icon"
              href={`./android-chrome-192×192.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`}
              sizes="192x192"
            />
            <link
              rel="icon"
              href={`./favicon-32×32.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`}
              sizes="32x32"
            />
          </Head>
          <ErrorBoundary>
            <div className={globalStyle}>
              <Component {...pageProps} />
            </div>
          </ErrorBoundary>
        </DisplayContext.Provider>
      </Provider>
    </Auth0Provider>
  );
}

export const globalStyle = css`
  font-family: -apple-system, BlinkMacSystemFont, Roboto,
    "Hiragino Kaku Gothic Pro", "Yu Gothic", "Noto Sans CJKS", Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333333;
  ${mediaQuery.landscape} {
    font-size: 16px;
  }
`;

export default GreenApp;
