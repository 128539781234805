// Or from '@reduxjs/toolkit/query/react'
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ActionContentDetail, actionContentDetailFields, ActionContentWithDetail, BingoCard, BingoCards } from "./types";

export const CMS_TAG_TYPE = {
  BINGO_CARDS: "BingoCards",
  BINGO_CARD: "BingoCard",
} as const;

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env["NEXT_PUBLIC_CMS_BASE_URL"] as string,
    prepareHeaders: (headers) => {
      headers.set(
        process.env["NEXT_PUBLIC_CMS_API_KEY_HEADER"] as string,
        process.env["NEXT_PUBLIC_CMS_API_KEY_VALUE"] as string
      );
      return headers;
    },
  }),
  reducerPath: "cms",
  tagTypes: [CMS_TAG_TYPE.BINGO_CARDS, CMS_TAG_TYPE.BINGO_CARD],
  endpoints: (builder) => ({
    getBingoCards: builder.query<BingoCards, void>({
      query: () => `bingocard?`,
    }),
    // 今今、利用想定なし
    getBingoCard: builder.query<BingoCard, string>({
      query: (contentId: string) => `bingocard/${contentId}`,
    }),
    getActionDetail: builder.query<ActionContentWithDetail, string>({
      query: (actionId: string) => `action/${actionId}`,
    })
  }),
});
export default api;
export const { useGetBingoCardsQuery, useGetBingoCardQuery, useGetActionDetailQuery } = api
