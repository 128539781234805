import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export interface DemandMonthStat {
  address_code: string,
  area_class: string,
  co2_emissions: string,
  meter_num: number,
  power_usage: number,
  use_category: number,
  year_month: string
}

type DemandMonthStats = DemandMonthStat[]

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env["NEXT_PUBLIC_CMS_BASE_URL"] as string,
    prepareHeaders: (headers) => {
      headers.set(
        process.env["NEXT_PUBLIC_CMS_API_KEY_HEADER"] as string,
        process.env["NEXT_PUBLIC_CMS_API_KEY_VALUE"] as string
      );
      return headers;
    },
    }),
    reducerPath: "viewer",
  endpoints: (builder) => ({
    getDemandMonthStat: builder.query<DemandMonthStats, void>({
      query: () => `DemandMonthStats`,
    }),
  }),
})
export default api;
// use + endpointsで設定した名前 + QueryでHooksが作られる
export const { useGetDemandMonthStatQuery } = api