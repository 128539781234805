import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { badgeGetArray, BadgeGetInfo } from "../../utils/bingo/badge";

export type privilege_sidemenu = {
  first_view: string;
  navi_menu: {
    area?: string[];
    institution?: string[];
  };
};

export type Auth0State = {
  accessToken?: string;
  userRole?: string; // auth0 ユーザーのapp_metadataにあるロール
  isRequiredCreatingAccount: boolean;
  userSidemenuData: privilege_sidemenu;
};

const initialState: Auth0State = {
  accessToken: undefined,
  userRole: undefined,
  isRequiredCreatingAccount: false,
  userSidemenuData: {
    first_view: "",
    navi_menu: {
      area: [],
      institution: [],
    },
  },
};

const auth0Slice = createSlice({
  name: "auth0",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => ({
      ...state,
      accessToken: action.payload,
    }),
    setUserRole: (state, action: PayloadAction<string>) => ({
      ...state,
      userRole: action.payload,
    }),
    setUserSidemenuData: (
      state,
      action: PayloadAction<privilege_sidemenu>
    ) => ({
      ...state,
      userSidemenuData: action.payload,
    }),
    requireCreatingAccount: (state, action: PayloadAction<void>) => ({
      ...state,
      isRequiredCreatingAccount: true,
    }),
  },
});

export default auth0Slice.reducer;
export const {
  setAccessToken,
  setUserRole,
  setUserSidemenuData,
  requireCreatingAccount,
} = auth0Slice.actions;
