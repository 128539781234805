import { createSlice } from "@reduxjs/toolkit";

export interface GrayBoxState {
    show: boolean
}

// Stateの初期状態
const initialState = {
  show: false
} as GrayBoxState

// 網掛けを生成する
const grayBoxSlice = createSlice({
  name: "grayBox",
  initialState,
  reducers: {
    showGrayBox: (state) => {
        state.show = true
    },
    hideGrayBox: state => {
        state.show = false
    },
    // etc...
  }
});

// Reducerをエクスポートする
export default grayBoxSlice.reducer;

// Action Creatorsをエクスポートする
export const { showGrayBox, hideGrayBox } = grayBoxSlice.actions;
