import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Privilege } from "../../components/organisms/ViewerFilter";
import { PrivilegePlaceinfo } from "../../components/organisms/FacilitiesFilter";
import { PLACE_DATA } from "../../components/molucules/FacilityDataGrid";
import { requestData } from "../../components/organisms/ViewerBarGraph";

export type ViewerState = {
  dataType: number; // データの種類
  addressCode: string; //住所コード
  areaClass?: number; // エリア粒度区分
  useCategory?: string; //部門分類
  voltageClass?: string; // 電圧区分
  generationSources?: string; // 発電区分
  gteDate: string; // 期限開始
  lteDate: string; // 期限最終
  polygonData?: { key: string }[];
  barData?: requestData[];
  graphDataName: string; // グラフで利用するデータの名前（power_usage）
  graphYTitle: string; // グラフで利用するデータの単位
  graphTableName: string; // グラフで利用するテーブルの名前
  mapDemandResponse?: { key: string }[];
  mapGenerateResponse?: { key: string }[];
  mapGenerateResponse2?: { key: string }[];
  mapMixResponse?: { key: string }[];
  error?: ViewerStateError;
  ooazaflag: boolean;
  privilege?: Privilege[];
  privilegeMapData: {};
  privilegePlaceinfo?: PrivilegePlaceinfo[];
  compareArea?: { key: string }[];
  placeData?: PLACE_DATA[]; //施設情報
  granularity: string; //粒度
  facilityResponse: facilityResponseData[] | []; //事務事業施設情報データ
  sortAddressCode: string[];
  filterData: any;
};

export type facilityResponseData = {
  year_month: string;
  power_usage: number;
  co2_emission_factor: number;
  target_power_usage: number;
  customer_id: string;
  place_id: string;
};

export const VIEW_STATE_ERROR_REASON = {
  NETWORK: "NETWORK",
} as const;

export type ViewerStateError = {
  reason: typeof VIEW_STATE_ERROR_REASON[keyof typeof VIEW_STATE_ERROR_REASON];
};

const initialState: ViewerState = {
  dataType: 1, // データの種類
  addressCode: "13000000000",
  areaClass: 5, // エリア粒度区分
  useCategory: "家庭", //部門分類
  voltageClass: "", // 電圧区分
  generationSources: "", // 発電区分
  gteDate: "2021-01-01", // 期限開始
  lteDate: "2022-12-01", // 期限最終
  graphDataName: "power_usage",
  graphYTitle: "Wh",
  graphTableName: "envpkg_demand_month_stat",
  polygonData: [],
  barData: [],
  mapDemandResponse: [],
  mapGenerateResponse: [],
  mapMixResponse: [],
  error: undefined,
  ooazaflag: false,
  privilege: [],
  privilegeMapData: {}, // 都道府県の地図データ
  privilegePlaceinfo: [],
  compareArea: [],
  placeData: [],
  granularity: "月別",
  facilityResponse: [],
  sortAddressCode: [],
  filterData: {},
};

// datatypeによってグラフ表示に必要なタイトル、テーブルネーム、Y軸の単位の選定
const graphDataFilter = (dataType: number) => {
  let graphDataName: string = "";
  let graphYTitle: string = "";
  let tableName: string = "";
  switch (dataType) {
    case 1:
      graphDataName = "power_usage";
      graphYTitle = "kWh";
      tableName = "envpkg_demand_month_stat";
      break;
    case 2:
      graphDataName = "co2_emissions";
      graphYTitle = "t-CO2";
      tableName = "envpkg_demand_month_stat";
      break;
    case 3:
      graphDataName = "meter_num";
      graphYTitle = "kWh";
      tableName = "envpkg_demand_month_stat";
      break;
    case 4:
      graphDataName = "facility_num";
      graphYTitle = "件";
      tableName = "envpkg_generate_month_stat_v2";
      break;
    case 5:
      graphDataName = "faciliry_capacity";
      graphYTitle = "kW";
      tableName = "envpkg_generate_month_stat_v2";
      break;
    case 6:
      graphDataName = "generate_amount";
      graphYTitle = "kWh";
      tableName = "envpkg_mix_month_stat";
      break;
    case 7:
      graphDataName = "self_usage";
      graphYTitle = "kWh";
      tableName = "envpkg_mix_month_stat";
      break;
    case 8:
      graphDataName = "power_usage_with_self"; // 電力使用量（太陽光発電の自家消費量含む）
      graphYTitle = "kWh";
      tableName = "envpkg_mix_month_stat";
      break;
    case 9:
      graphDataName = "self_usage_ratio"; // 自家消費量の割合
      graphYTitle = "%";
      tableName = "envpkg_mix_month_stat";
      break;
    case 10:
      graphDataName = "facility_num";
      graphYTitle = "件";
      tableName = "envpkg_generate_month_stat";
      break;
    case 11:
      graphDataName = "faciliry_capacity";
      graphYTitle = "kW";
      tableName = "envpkg_generate_month_stat";
      break;
  }
  return [graphDataName, graphYTitle, tableName];
};

// datatypeによってグラフ表示に必要なタイトル、テーブルネーム、Y軸の単位の選定
const graphFacilityDataFilter = (dataType: number) => {
  let graphDataName: string = "";
  let graphYTitle: string = "";
  let tableName: string = "";
  switch (dataType) {
    case 1:
      graphDataName = "power_usage";
      graphYTitle = "kWh";
      break;
    case 2:
      graphDataName = "co2_emissions";
      graphYTitle = "kg-CO2";
      break;
  }
  return [graphDataName, graphYTitle, tableName];
};

const viewerSlice = createSlice({
  name: "viewer",
  initialState,
  reducers: {
    setFilterData: (state, action) => {
      // state
      state.filterData = action.payload;
    },
    setGraphData: (state, action) => {
      // state
      state.dataType = action.payload;
    },
    setDataType: (state, action) => {
      state.dataType = action.payload;
      const [graphDataName, graphYTitle, tableName] = graphDataFilter(
        action.payload
      );
      state.graphDataName = graphDataName;
      state.graphYTitle = graphYTitle;
      state.graphTableName = tableName;
    },
    setFacilityDataType: (state, action) => {
      state.dataType = action.payload;
      const [graphDataName, graphYTitle, tableName] = graphFacilityDataFilter(
        action.payload
      );
      state.graphDataName = graphDataName;
      state.graphYTitle = graphYTitle;
    },
    setMapPrivilege: (state, action) => {
      state.privilegeMapData = action.payload;
    },
    setAddressCode: (state, action) => {
      state.addressCode = action.payload;
    },
    setSortAddressCode: (state, action) => {
      state.sortAddressCode = action.payload;
    },
    setUseCategory: (state, action) => {
      state.useCategory = action.payload;
    },
    setVoltageClass: (state, action) => {
      state.voltageClass = action.payload;
    },
    setGenerationSources: (state, action) => {
      state.generationSources = action.payload;
    },
    setGteDate: (state, action) => {
      state.gteDate = action.payload;
    },
    setLteDate: (state, action) => {
      state.lteDate = action.payload;
    },
    setPolygon: (state, action) => {
      state.polygonData = action.payload;
    },
    setBarData: (state, action) => {
      state.barData = action.payload;
    },
    setMapDemandResponse: (state, action) => {
      state.mapDemandResponse = action.payload;
    },
    setMapGenerateResponse: (state, action) => {
      state.mapGenerateResponse = action.payload;
    },
    setMapGenerateResponse2: (state, action) => {
      state.mapGenerateResponse2 = action.payload;
    },
    setMapMixResponse: (state, action) => {
      state.mapMixResponse = action.payload;
    },
    setAreaClass: (state, action) => {
      state.areaClass = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    ooazaNovalue: (state) => {
      state.ooazaflag = false;
    },
    ooazahaveValue: (state) => {
      state.ooazaflag = true;
    },

    setPrivilege: (state, action) => {
      state.privilege = action.payload;
    },
    setPrivilegePlaceinfo: (state, action) => {
      state.privilegePlaceinfo = action.payload;
    },
    setCompareArea: (state, action) => {
      state.compareArea = action.payload;
    },
    setPlaceData: (state, action) => {
      state.placeData = action.payload;
    },
    setGranularity: (state, action) => {
      state.granularity = action.payload;
    },
    setFacilityResponse: (state, action) => {
      state.facilityResponse = action.payload;
    },
  },
  extraReducers: () => {},
});

export default viewerSlice.reducer;

export const {
  setDataType,
  setFacilityDataType,
  setGenerationSources,
  setAddressCode,
  setGteDate,
  setLteDate,
  setUseCategory,
  setVoltageClass,
  setPolygon,
  setBarData,
  setMapDemandResponse,
  setMapGenerateResponse,
  setMapGenerateResponse2,
  setMapMixResponse,
  setAreaClass,
  setError,
  setFilterData,
  setSortAddressCode,
  ooazahaveValue,
  ooazaNovalue,
  setPrivilege,
  setMapPrivilege,
  setPrivilegePlaceinfo,
  setCompareArea,
  setPlaceData,
  setGranularity,
  setFacilityResponse,
} = viewerSlice.actions;
