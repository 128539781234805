import { createSlice } from "@reduxjs/toolkit";

export interface LoadingSpinerState {
    show: boolean
}

// Stateの初期状態
const initialState = {
  show: false
} as LoadingSpinerState

// Sliceを生成する
const loadingSpinerSlice = createSlice({
  name: "loadingSpiner",
  initialState,
  reducers: {
    showSpiner: (state) => {
        state.show = true
    },
    hideSpiner: state => {
        state.show = false
    },
    // etc...
  }
});

// Reducerをエクスポートする
export default loadingSpinerSlice.reducer;

// Action Creatorsをエクスポートする
export const { showSpiner, hideSpiner } = loadingSpinerSlice.actions;
