import { Store, combineReducers, AnyAction } from "redux";
import logger from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { api as graphql } from "./graphql";
import greenActionApi from "../services/cms/greenAction";
import viewerApi from "./viewer";
import loadingSpinerReducer from "./viewer/loadingSpiner";
import grayBoxActionReducer from "./viewer/grayBox";
import auth0BackendApi from "../services/auth0/";
import GreenActionReducer from "./greenAction/slice";
import Auth0ActionReducer from "./auth0/slice";
import facilitiesReducer from "./facilities/slice";
import viewerReducer from "./viewer/slice";
import compareReducer from "./compare/slice";
import ErrorReducer from "./error/slice";
import auth0UserInfoApi from "./auth0/userInfo";
import FacilitiesReducer from "./facilities/slice";
// import mapApi from
const store = configureStore({
  reducer: {
    greenAction: GreenActionReducer,
    viewerAction: viewerReducer,
    facilitiesAction: facilitiesReducer,
    loadingSpinerAction: loadingSpinerReducer,
    grayBoxAction: grayBoxActionReducer,
    auth0Action: Auth0ActionReducer,
    compareAction: compareReducer,
    error: ErrorReducer,
    [graphql.reducerPath]: graphql.reducer,
    [viewerApi.reducerPath]: viewerApi.reducer,
    [greenActionApi.reducerPath]: greenActionApi.reducer,
    [auth0BackendApi.reducerPath]: auth0BackendApi.reducer,
    [auth0UserInfoApi.reducerPath]: auth0UserInfoApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(viewerApi.middleware)
      .concat(graphql.middleware)
      .concat(greenActionApi.middleware)
      .concat(auth0BackendApi.middleware)
      .concat(auth0UserInfoApi.middleware),
  // .concat(logger),
  devTools: process.env.NODE_ENV !== "production",
  // preloadedState: preloadedState(),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
